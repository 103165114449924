<template>
  <div class="none wrapper">
    <div class="font-20 mb-20">您访问的地址不存在</div>
    <a-button type="primary" @click="goToIndex()">返回首页</a-button>
  </div>
</template>

<script>
import { defineComponent } from "vue-demi";
import url from "@/utils/url.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "404",
  setup() {
    const route = useRouter();
    console.log("route", route.getRoutes());
    const goToIndex = () => {
      url.replaceTo("/index");
    };
    return {
      goToIndex
    };
  }
});
</script>
<style>
.none{
 padding: 40px 0;

}
</style>

